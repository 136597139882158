@import "../../ressources/Variables.scss";

.grid-wrap{
    position: relative;
    max-width: 1500px;
    margin: 0 auto;
    padding: 6rem 1rem 0;
    margin-top: 100px;
}

.grid{
    margin: 0 auto;
    position: relative;
}

.grid-item{
    width: 260px;
    position: relative;
    margin-bottom: 20rem;
    padding: 2.75rem;

    &:nth-child(2){
        margin-top: 100px;
    }

    &:nth-child(3){
        margin-top: -80px;
    }


    &--bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;
        z-index: -1;
    }


    &--wrap{
        position: relative;
    }


    &--img{
        pointer-events: none;
        position: relative;
        max-width: 100%;
        max-height: 300px;
        margin: 0 auto;
        display: block;
        outline: 1px solid transparent;
    }

    &:nth-child(odd) .grid-item--img{
        transform: rotate3d(0,0,1,-4deg);
    }

    &:nth-child(even) .grid-item--img{
        transform: rotate3d(0,0,1,4deg);
    }

    &--category{
        font-weight: 400;
        position: absolute;
        left: -20px;
        top: -5px;
        margin: 0 0 0 1rem;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        transform-origin: 0 50%;
    }


    &--title{
        font-weight: 700 !important;
        position: absolute;
        right: -20px;
        bottom: -20px;
        margin: 0 0 0.15rem;
    }


 
    .category-letters{
        span{
            display: inline-block;
        }
    }

    
}