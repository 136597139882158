@import "../ressources/Variables.scss";
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.header{
    height: 100px;
    position: relative;
    z-index: 1000;

    .header-fixed{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        width: 100%;
        padding: 0 15px !important;
        background-color: $bgColor;
    }

    a{
        display: block;
        &:hover{
            text-decoration: none;
        }

        &.active{
            font-weight: 800;
        }
    }
}


@include media-breakpoint-up(lg){
    .header{
        .header-fixed{
            padding: 0 100px !important;
            background-color: transparent;
        }
    
        a{
            display: inline-block;
        }
    
        .header-right{
            a{
                position: relative;
            }
            a:last-child{
                margin-left: 100px;
                &:before{
                    content: '';
                    display: block;
                    height: 1px;
                    background-color: $blue;
                    width: calc(100px - 30px);
                    position: absolute;
                    right: calc(100% + 15px);
                    top: 50%;
                }
            }
        }
    }
}