@import "../../ressources/Variables.scss";

.section-intro{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .roles{
        display: flex;
        flex-wrap: wrap;
        p{
            padding: 20px 50px;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:before{
                content: '';
                display: block;
                width: 50px;
                height: 50px;
                background-size: contain;
            }
        }
    }
}





.ux-design{
    &:before{
        -webkit-mask: url('../../ressources/images/icons/ux-design.svg') no-repeat center;
        mask: url('../../ressources/images/icons/ux-design.svg') no-repeat center;
    }
}

.ui-design{
    &:before{
        -webkit-mask: url('../../ressources/images/icons/ui-design.svg') no-repeat center;
        mask: url('../../ressources/images/icons/ui-design.svg') no-repeat center;
    }
}

.illustration{
    &:before{
        -webkit-mask: url('../../ressources/images/icons/illustration.svg') no-repeat center;
        mask: url('../../ressources/images/icons/illustration.svg') no-repeat center;
    }
}

.interactions{
    &:before{
        -webkit-mask: url('../../ressources/images/icons/interactions.svg') no-repeat center;
        mask: url('../../ressources/images/icons/interactions.svg') no-repeat center;
    }
}

.lettering{
    &:before{
        -webkit-mask: url('../../ressources/images/icons/lettering.svg') no-repeat center;
        mask: url('../../ressources/images/icons/lettering.svg') no-repeat center;
    }
}