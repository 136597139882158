@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import "../../ressources/Variables.scss";

.text-prez{
    h1, h4{
        font-weight: 700;
        color: $dark;
    }


    h2{
        font-weight: 300;
    }
}



#illustrations .section-simple, #vector section:nth-child(2){
    padding-top: 0 !important;
    margin-top: -30%;
}


#phoenix .band{
    background-color: #F5E0A9 !important;
}

#replaylist{
    .section-bubble{
        img{
            height: 80vh;
            width: auto;
        }
        .bubble-img-container{
            svg{
                display: none;
            }
        }
    }
}

@include media-breakpoint-up(md){
    section{
        .small-width{
            width: 50%;
        }
    }
}

@include media-breakpoint-up(lg){
    section{
        .small-width{
            width: 40%;
        }
    }

    #illustrations .section-simple, #vector section:nth-child(2){
        padding-top: 0 !important;
        margin-top: -15%;
    }


    #replaylist{
        .section-bubble{
            img{
                height: 60vh;
            }
            .bubble-img-container{
                svg{
                    display: none;
                }
            }
            .bubble-second-img {
                margin-top: -30%;
            }
        }
    }
}