@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.bubble-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    svg{
        position: absolute;
        width: 100%;
    }
    img{
        // width: 30%;
        
        z-index: 1;
        position: relative;
    }
}

.bubble-second-img{
    margin-top: 50px;
}


@include media-breakpoint-up(lg){
    .bubble-second-img{
        margin-top: -50px;
    }
}