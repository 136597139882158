@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';


.grid-sizer, .grid-item-50{
    width: calc(100% - 30px);
}
.grid-item-50{
    margin-bottom: 30px;
}


@include media-breakpoint-up(lg){
    .gutter-sizer{
        width: 60px;
    }
    .grid-sizer, .grid-item-50{
        width: calc(50% - 40px);
    }
}