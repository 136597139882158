@import "../ressources/Variables.scss";
.about{
    align-items: flex-end;
    &-title{
        color: $blue;
    }
    &-image{
        width: 100%;
        height: 435px;
        background-size: cover;
        background-position: center;
    }
    &-text, &-cv{
        font-size: 1.8rem;
        span{
            color: $blue;
            font-weight: 600;
        }
    }
}