@import "../../ressources/Variables.scss";

.section-band{
    position: relative;
    height: auto;
    min-height: 300px;
    .band{
        width: 100%;
        height: 200px;
        position: absolute;
        bottom: 0;
    }
    img{
        max-height: 650px;
        position: relative;
        margin: auto;
        display: block;
        padding: 15px;
    }
    
}


@media (min-width: 992px) {
    .section-band{
        height: 70vh;
        .band{
            height: 500px;
        }
    }
}